import React from "react";
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Helmet } from 'react-helmet';
import {Link} from "gatsby"
import loadable from '@loadable/component'
import {graphql} from "gatsby"
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SignInForm = loadable(() => import('../components/signinform/form'))
SignInForm.preload()
const SignIn = () => {
    const {t} = useTranslation();
    return (
      <>      
        <HeardTopWrapper title={t('SIGNIN')}/>
        <Layout>
          <Helmet>
            <title>{t('myKitchenInspector')}｜{t('SIGNIN')}</title>
          </Helmet>
          <PageContainer>
            <div className="signInItem">
              <SignInForm />
              <div className="or-line">
                <span>{t('OR')}</span>
              </div>
              <p className="text-center">{t('registerForFreeTrial')}</p>
              <p className="text-center">{t('pleaseClick')} <Link to="/startfreetrial">{t('here')}</Link> {t('toStartFreeTrial')}</p>
            </div>
          </PageContainer>
        </Layout>
      </>
      )
}
export default SignIn;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
